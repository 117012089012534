import React from 'react';
import styled from 'styled-components';
import { Grid } from '../../shared/Grid';
import { Richtext } from '../../shared/Richtext';
import { Typography } from '../../shared/Typography';

import {
  component, minTablet, desktopVW, mobileVW,
} from '../../styles';

interface componentProps {
  heading: string
  column1: string
  column2: string
}

export const Text: React.FC<componentProps> = (data) => {
  const {
    heading, column1, column2,
  } = data;
  return (
    <Component>
      <Grid>
        <Column1>
          {heading && (
            <Heading size="is-md" variants="h2">{ heading }</Heading>
          )}
          <Richtext>{ column1 || '' }</Richtext>
        </Column1>
        <Column2>
          <Richtext>{ column2 || '' }</Richtext>
        </Column2>
      </Grid>
    </Component>
  );
};

const Component = styled.section`
  ${component};
`;

const Column1 = styled.div`
  grid-column: 2 / 12;
  margin: 0 ${mobileVW(-15)};

  ${minTablet} {
    margin: 0;
    grid-column: 2 / 7;
    padding: 0 ${desktopVW(60)};
  }
`;

const Column2 = styled.div`
  grid-column: 2 / 12;
  margin: 0 ${mobileVW(-15)} ${mobileVW(30)};
  
  ${minTablet} {
    margin: 0;
    grid-column: 7 / 12;
    padding: 0 ${desktopVW(60)};
  }
`;

const Heading = styled(Typography)`
  margin-bottom: ${mobileVW(50)};
  ${minTablet} {
    margin-bottom: ${desktopVW(50)};
  }
`;
