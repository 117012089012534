import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {
  component, desktopVW,
} from '../../styles';

interface componentProps {
  image: {
    altText: string
    title: string
    localFile: {
      childImageSharp: {
        fluid: {
          src: string
        }
      }
    }
  }
}

export const Image = ({ image }: componentProps) => (
  <Container>
    <StyledImage
    // @ts-ignore ???????
      fluid={image.localFile.childImageSharp.fluid}
      alt={image.altText}
    />
  </Container>
);

const Container = styled.div`
  ${component};
`;

const StyledImage = styled(Img)`
  height: ${desktopVW(795)};
`;
