/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  component, desktopVW, minTablet, mobileVW, color, linkSm,
} from '../styles';
import { Typography } from '../shared/Typography';

export const Partners = ({ partner, title } : { partner: any, title: string }) => (
  <StyledPartners className="component">
    {title && (
    <StyledTitle variants="h4" size="intro-is-rg">
      {title}
    </StyledTitle>
    )}
    <StyledContainer>
      {partner.map((item : any, index : number) => (
        // Geen unieke waardes
        // eslint-disable-next-line react/no-array-index-key
        <StyledItem key={index}>
          {/* SVG */}
          <ImageContainer>
            <StyledImage alt={item.image.altText} src={item.image.sourceUrl} />
          </ImageContainer>
          <StyledItemTitle variants="h4" size="intro-is-rg">{item.title}</StyledItemTitle>
          <P>{item.description}</P>
        </StyledItem>
      ))}
    </StyledContainer>
  </StyledPartners>
);

const StyledPartners = styled.section`
  ${component};
  margin-left: ${mobileVW(15)};
  margin-right: ${mobileVW(15)};

  ${minTablet} {
    margin-left: ${desktopVW(60)};
    margin-right: ${desktopVW(60)};
  }
  /* text-align: center; */
`;

const StyledTitle = styled(Typography)`
  margin-bottom: ${mobileVW(50)};

  ${minTablet} {
    margin-bottom: ${desktopVW(50)};
  }
`;

const StyledItemTitle = styled(Typography)`
  margin-bottom: ${mobileVW(20)};

  ${minTablet} {
    margin-bottom: ${desktopVW(20)};
  }
`;

const StyledImage = styled.img`
  width: ${mobileVW(117)};
  display: block;
  margin: 0 auto ${mobileVW(30)};
  height: ${mobileVW(120)};

  ${minTablet} {
    width: ${desktopVW(160)};
    height: ${desktopVW(160)};
    margin: 0 auto ${desktopVW(30)};
  }
`;

const P = styled.p`
  ${linkSm};
  color: ${color.greyDark};
  /* opacity: 0.5; */
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  
`;

const StyledItem = styled.div`
  & + & {
    margin-top:  ${mobileVW(50)};
  }

  ${minTablet} {
    & + & {
      margin-top:  0;
    }
    width: ${desktopVW(284)};
  }
`;

const StyledContainer = styled.div`
  margin: 0 ${desktopVW(40)};
  text-align: center;

  ${minTablet} {
    margin: 0 ${desktopVW(30)};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
  }
`;
