import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../shared/Grid';
import { Richtext } from '../shared/Richtext';

import {
  component, headingSm, headingXxs, introRg, minTablet, desktopVW, mobileVW, color,
} from '../styles';

interface componentProps {
  section: [{
    heading: string
    name: string
    text: string
    type: string
    faq: [{
      answer: string
      question: string
    }]
  }]
}

export const Faq = ({ section }: componentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  // const [currentQuestion, setCurrentQuestion] = useState(999);

  const handleClick = (index: number) => {
    setCurrentTab(index);
  };

  // const handleFaq = (i: number) => {
  //   setCurrentQuestion(i);
  // };

  return (
    <StyledGrid className="component">
      <Ul>
        {section.map((item : any, index : number) => (
          <Li
            key={item.name}
            onClickCapture={() => handleClick(index)}
            style={{ textDecoration: index === currentTab ? 'underline' : 'none' }}
          >
            {item.name}
          </Li>
        ))}
      </Ul>
      {section.map((item : any, index : number) => item.heading && (
        <Content key={item.heading} style={{ display: index === currentTab ? 'block' : 'none' }}>
          <Heading>
            {item.heading}
          </Heading>
          {item.type === 'text'
            ? <Richtext>{item.text}</Richtext>
            : item.faq && item.faq.map((faq : any) => {
              const [active, setActive] = useState(false);

              return (
                <StyledDiv key={faq.question}>
                  <Question isActive={active} onClick={() => setActive(!active)}>
                    {faq.question}
                  </Question>
                  <Answer style={{ display: active ? 'block' : 'none' }}>
                    <Richtext>{faq.answer}</Richtext>
                  </Answer>
                </StyledDiv>
              );
            })}
        </Content>
      ))}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  ${component};
`;

const Ul = styled.ul`
  grid-column: 2 / 12;
  margin: 0 ${mobileVW(-15)} ${mobileVW(30)};
  
  ${minTablet} {
    margin: 0;
    padding-left: ${desktopVW(60)};
    grid-column: 1 / 5;
  }
`;

const Li = styled.li`
  ${headingSm}
  :hover {
    cursor: pointer;
  }
`;

const Heading = styled.h3`
  ${introRg}
  margin-bottom: ${mobileVW(50)};
  ${minTablet} {
    margin-bottom: ${desktopVW(60)};
  }
`;

const Content = styled.div`
  grid-column: 2 / 12;
  margin: 0 ${mobileVW(-15)};

  ${minTablet} {
    margin: 0;
    padding-right: ${desktopVW(60)};
    grid-column: 6 / 13;
  }
`;

const StyledDiv = styled.div`
  padding: ${mobileVW(60)} 0;
  border-top: 1px solid ${color.greyLight};
  :last-child {
    border-bottom: 1px solid ${color.greyLight};
  }
  ${minTablet} {
    padding: ${desktopVW(60)} 0;
  }
`;

const Question = styled.h3`
  ${headingXxs}
  position: relative;
  &:after {
    position: absolute;
    right: 0;
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3Ljk2NyA0LjU0MSI+PHBhdGggZD0iTTMuOTgzIDQuNTQxYS41NTYuNTU2IDAgMDEtLjM5NC0uMTYzTC4xNjMuOTUyQS41Ni41NiAwIDAxLjk1Mi4xNTlMMy45ODMgMy4xOSA3LjAxNC4xNTlhLjU1OC41NTggMCAwMS43ODkuNzg5TDQuMzc3IDQuMzc0YS41NTYuNTU2IDAgMDEtLjM5NC4xNjd6Ii8+PC9zdmc+');
    background-repeat: no-repeat;
    background-size: contain;
    top: ${mobileVW(10)};
    height: ${mobileVW(4.5)};
    width: ${mobileVW(8)} ;
    transform: rotate(${({ isActive } : {isActive: any}) => (isActive ? '180' : '0')}deg);

    ${minTablet} {
      top: ${desktopVW(16)};
      height: ${desktopVW(4.5)};
      width: ${desktopVW(8)} ;
    }
  }
  :hover {
    cursor: pointer;
  }
  ${minTablet} {
    padding-right: ${desktopVW(60)};
  }
`;

const Answer = styled.div`
  margin-top:${mobileVW(30)};
  ${minTablet} {
    margin-top:${desktopVW(30)};
  }
`;
