/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';

// C project copied from codepen
// Modified images and loop to destroy the interval on pageleave
export const Confetti = ({ className } : any) => {
  const refCanvas = useRef(null);

  useEffect(() => {
    // @ts-ignore
    const confettiCtx = refCanvas.current.getContext('2d');
    const canvas:any = refCanvas.current;
    const ratio = Math.ceil(window.devicePixelRatio);
    const img:any = [];

    const loop = setInterval(() => { counter += 1; }, (Math.random() * 1000) + 500);

    let counter:number = 0;
    let container:any;
    let confettiPosition:any;

    const confettiElements:any = [];

    // helper
    const rand = (min:number, max:number) => Math.random() * (max - min) + min;

    // params to play with
    const confettiParams = {
      images: [
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 59.4 31.6' style='enable-background:new 0 0 59.4 31.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230055FF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M29.7,0C13.3,0,0,13.6,0,30.3v1.3h18.8v-1.3c0-6.2,4.9-11.2,10.9-11.2s10.9,5,10.9,11.2v1.3h18.8v-1.3 C59.4,13.6,46.1,0,29.7,0'/%3E%3C/svg%3E%0A",
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 45.1 62' style='enable-background:new 0 0 45.1 62;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2341AF32;%7D%0A%3C/style%3E%3Cpath class='st0' d='M17.6,14c1.4,2.2,2.1,4.9,2.1,7.7c0,8.2-6.6,14.9-14.8,14.9v0H0V62h5v0c22.2,0,40.2-18,40.2-40.3 c0-8-2.3-15.4-6.3-21.7C33.1,6.3,25.9,11.2,17.6,14'/%3E%3C/svg%3E%0A",
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 43.1 82.6' style='enable-background:new 0 0 43.1 82.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF0550;%7D%0A%3C/style%3E%3Cpath class='st0' d='M43.1,41.3C43.1,18.5,24.6,0,1.8,0H0v26.1h1.8c8.4,0,15.2,6.8,15.2,15.2c0,8.4-6.8,15.2-15.2,15.2H0v26.1h1.8 C24.6,82.6,43.1,64.1,43.1,41.3'/%3E%3C/svg%3E%0A",
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 39.2 45.6' style='enable-background:new 0 0 39.2 45.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230055FF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,7l16.7,38.6L39.2,44L20.2,0c-5.8,4-12.6,6.5-20,7L0,7z'/%3E%3C/svg%3E%0A",
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 82.6 50.4' style='enable-background:new 0 0 82.6 50.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2341AF32;%7D%0A%3C/style%3E%3Crect x='-0.3' y='16.8' transform='matrix(0.9063 -0.4226 0.4226 0.9063 -6.7896 19.8085)' class='st0' width='83.2' height='16.8'/%3E%3C/svg%3E%0A",
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 35 39.5' style='enable-background:new 0 0 35 39.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF0550;%7D%0A%3C/style%3E%3Cpath class='st0' d='M35,32.8L19.4,0L0,2l17.8,37.5c4.9-3.6,10.7-6,17-6.6L35,32.8z'/%3E%3C/svg%3E%0A",
      ],
      // number of canvas per "explosion"
      number: 40,
      // min and max size for each rectangle
      size: { x: [20, 80], y: [40, 80] },
      // power of explosion
      initSpeed: 35,
      // defines how fast particles go down after blast-off
      gravity: 0.65,
      // how wide is explosion
      drag: 0.5,
      // how slow particles are falling
      terminalVelocity: 1,
      // how fast particles are rotating around themselves
      flipSpeed: 0.007,
    };

    setupCanvas();

    confettiParams.images.map((item) => {
      const imgClass = new Image();
      imgClass.src = item;
      return img.push(imgClass);
    });

    img[0].onload = imgLoaded;

    function imgLoaded() {
      updateConfetti();
    }

    // Confetti constructor
    function Conf(this: any) {
      const randomModifier = rand(-1, 1);
      this.dimensions = {
        x: rand(confettiParams.size.x[0], confettiParams.size.x[1]),
        y: rand(confettiParams.size.y[0], confettiParams.size.y[1]),
      };
      this.position = {
        x: confettiPosition[0],
        y: confettiPosition[1],
      };
      this.rotation = rand(0, 2 * Math.PI);
      this.scale = { x: 1, y: 1 };
      this.velocity = {
        x: rand(-confettiParams.initSpeed, confettiParams.initSpeed) * 0.4,
        y: rand(-confettiParams.initSpeed, confettiParams.initSpeed),
      };
      this.flipSpeed = rand(0.2, 1.5) * confettiParams.flipSpeed;

      if (this.position.y <= container.h) {
        this.velocity.y = -Math.abs(this.velocity.y);
      }

      this.image = img[Math.floor(rand(0, img.length))];

      this.terminalVelocity = rand(1, 1.5) * confettiParams.terminalVelocity;

      this.update = () => {
        this.velocity.x *= 0.98;
        this.position.x += this.velocity.x;

        this.velocity.y += (randomModifier * confettiParams.drag);
        this.velocity.y += confettiParams.gravity;
        this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
        this.position.y += this.velocity.y;

        this.scale.y = Math.cos((this.position.y + randomModifier) * this.flipSpeed);
      };
    }

    async function updateConfetti() {
      confettiCtx.clearRect(0, 0, container.w, container.h);

      confettiElements.forEach((c: { update: () => void; position: { x: any; y: any; }; rotation: any; dimensions: { x: number; y: number; }; scale: { x: number; y: number; }; image: any; }) => {
        c.update();
        confettiCtx.translate(c.position.x, c.position.y);
        confettiCtx.rotate(c.rotation);
        const width = (c.dimensions.x * c.scale.x);
        const height = (c.dimensions.y * c.scale.y);
        confettiCtx.drawImage(c.image, 0, 0, width, height);

        confettiCtx.setTransform(ratio, 0, 0, ratio, 0, 0);
      });

      confettiElements.forEach((c: { position: { y: number; x: number; }; }, idx: any) => {
        if (c.position.y > (container.h + 80)
              || c.position.x < -0.5 * container.x
              || c.position.x > 1.5 * container.x) {
          confettiElements.splice(idx, 1);
        }
      });
      window.requestAnimationFrame(updateConfetti);
    }

    function setupCanvas() {
      container = {
        w: canvas.clientWidth,
        h: canvas.clientHeight,
      };
      canvas.width = container.w * ratio;
      canvas.height = container.h * ratio;
    }

    const addConfetti = () => {
      const canvasBox = canvas.getBoundingClientRect();

      confettiPosition = [
        canvasBox.width * Math.random(),
        canvasBox.height * Math.random(),
      ];

      // @ts-ignore
      [...Array(confettiParams.number)].map(() => confettiElements.push(new Conf()));
    };

    confettiLoop();
    addConfetti();

    function confettiLoop() {
      if (counter >= 6) {
        counter = 0;
        addConfetti();
      }
      window.requestAnimationFrame(confettiLoop);
    }

    return () => {
      // destroy interval
      clearInterval(loop);
    };
  }, [refCanvas]);

  return (
    <canvas ref={refCanvas} className={className} />
  );
};
