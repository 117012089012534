import parse from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';
import {
  minTablet, mobileVW, desktopVW,
} from '../styles';

export const Richtext = ({ children, className, text } : { children: any, className?: string | undefined, text?: string}) => (
  <StyledRichtext className={className}>
    {text
      ? (
        <>
          {parse(text)}
          {children}
        </>
      ) : parse(children)}
  </StyledRichtext>
);

const StyledRichtext = styled.div`
  p, ul, ol, blockquote {
    margin-bottom: ${mobileVW(30)};

    ${minTablet} {
      margin-bottom: ${desktopVW(30)};
    }
  }

  ul {
    list-style:  inside;
  }

  ol {
    list-style: inside decimal;
  }

  strong {
    font-weight: bold;
  }

  *:last-child,
  * *:last-child {
    margin-bottom: 0;
  }
`;
