/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
// import Image from 'gatsby-image';
import {
  color,
  component,
  desktopVW,
  letterSpacing,
  linkSm,
  minTablet,
  mobileVW,
} from '../styles';
import { Typography } from '../shared/Typography';
import { Richtext } from '../shared/Richtext';
import { Button } from '../shared/Button';
// import { Grid } from '../shared/Grid';
import { Confetti } from '../shared/Confetti';

export const Cta = ({
  column1, column2, title, button,
} : {column1: string, column2: string, title: string, button?: any }) => (
  <StyledCta className="component">
    <StyledConfetti />
    <StyledTitle variants="h3" size="is-xs">{title}</StyledTitle>
    <Container>
      <StyledColumn>
        {column1}
      </StyledColumn>
      <StyledColumn text={column2}>
        {button && (
          <>
            {button.title === 'beginnen' ? (
              <StyledButton
                // @ts-ignore
                handleClick={() => document.getElementById('start').scrollIntoView({ behavior: 'smooth' })}
                size="is-sm"
                type="button"
              >
                {button.title}
              </StyledButton>
            ) : (
              <StyledButton size="is-sm" type="link" url={button.url}>
                {button.title}
              </StyledButton>
            )}
          </>
        )}
      </StyledColumn>
    </Container>
  </StyledCta>
);

const StyledCta = styled.section`
  ${component};
  background: ${color.black};
  color: ${color.white};
  padding: ${mobileVW(75)} ${mobileVW(15)} ${mobileVW(50)};
  margin-left: ${mobileVW(15)};
  margin-right: ${mobileVW(15)};
  justify-content: space-between;
  border-radius: ${mobileVW(20)};
  overflow: hidden;
  
  ${minTablet} {
    border-radius: ${desktopVW(40)};
    display: grid;
    padding: ${desktopVW(75)} ${desktopVW(30)} ${desktopVW(50)} ${desktopVW(60)};
    margin-left: ${desktopVW(60)};
    margin-right: ${desktopVW(60)};
    grid-template-columns: ${desktopVW(390)} auto;
  }
`;

const StyledTitle = styled(Typography)`
  color: ${color.white};
  margin-bottom: ${mobileVW(30)};
  position: relative;
  z-index: 1;

  ${minTablet} {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 1;

  ${minTablet} {
    display: flex;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${desktopVW(30)};
  }
`;

const StyledColumn = styled(Richtext)`
  ${minTablet} {
    width: ${desktopVW(330)};
  }

  p {
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(32)};

    ${minTablet} {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(32)};
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${mobileVW(30)};
  background: ${color.white};
  color: ${color.black};
  text-transform: capitalize;
  border: none;
  ${linkSm};
  letter-spacing: ${letterSpacing(30)};
  border: 1px solid ${color.black};
  
  &:hover {
    background: ${color.black};
    border: 1px solid #fff;
  }

  transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

  ${minTablet} {
    margin-top: ${desktopVW(30)};
  }
`;

const StyledConfetti = styled(Confetti)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: ${mobileVW(20)};
  
  ${minTablet} {
    border-radius: ${desktopVW(40)};
  }
`;
