/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import {
  color,
  component,
  desktopVW,
  linkSm,
  maxTablet,
  minTablet,
  mobileVW,
} from '../styles';
import { Typography } from '../shared/Typography';
import { Grid } from '../shared/Grid';
import { Button } from '../shared/Button';
import { Confetti } from '../shared/Confetti';

export const FullWidth = ({
  title, button, image, subtitle, secondButton,
}: {title: string, subtitle: string, secondButton?: any, button?: {url: string, title: string}, image: any}) => (
  <StyledContainer>
    {/* @ts-ignore */}
    <StyledImage alt={image.title} fluid={image.localFile.childImageSharp.fluid} />
    <StyledWrapper>
      <FullWidthTitle size="is-md" variants="h2">
        {title}
      </FullWidthTitle>
      <StyledSub>{subtitle}</StyledSub>
      {button && (
        <Button size="is-sm" url={button.url} type="transparent">
          {button.title}
        </Button>
      )}
      {secondButton && (
        <Button size="is-sm" url={secondButton.url} type="transparent">
          {secondButton.title}
        </Button>
      )}
    </StyledWrapper>
  </StyledContainer>
);

export const GridContainer = ({
  items, desktopHeight, mobileHeight,
} : {items: any, desktopHeight: number, mobileHeight: number}) => (
  <StyledGridContainer>
    {items.map(({
      title, image, button, imageThumb, subtitle, price, info,
    } : any, index: any) => {
      const [colorCard, setColorCard] = useState(color.white);
      const getColor = (event : {type: string}) => {
        if (event && event.type === 'mouseenter') return setColorCard(color.white);
        if (event && event.type === 'mouseleave') return setColorCard(color.black);
        if (imageThumb) return setColorCard(color.black);
        return setColorCard(color.white);
      };

      return (
      // @ts-ignore
        <StyledImageCard type={!button && 'div'} to={button && button.url} hover={!!image} onLoad={() => getColor()} onMouseEnter={(e) => imageThumb && image && getColor(e)} onMouseLeave={(e) => imageThumb && image && getColor(e)} cardColor={colorCard} key={index} desktopPx={desktopHeight} mobilePx={mobileHeight}>
          {/* CONTENT */}
          {subtitle && (
          <StyledSubtitle>{subtitle}</StyledSubtitle>
          )}
          {title && (
          <StyledCardTitle size="is-sm" variants="h3">{title}</StyledCardTitle>
          )}

          {/* IMAGE, HOVERIMAGE AND CONFETTI RENDERS */}
          {image && (
          <StyledOverlay>
            <div className="overlay" />
            {/* @ts-ignore */}
            <StyledImage className="image__animation" alt={image.title} fluid={image.localFile.childImageSharp.fluid} />
          </StyledOverlay>
          )}
          {!image && !imageThumb && (
          <StyledOverlay>
            <div className="overlay" />
            <StyledConfetti />
          </StyledOverlay>
          )}
          {imageThumb && (
          <StyledBackground className="banner__hover">
            {/* @ts-ignore */}
            <StyledImageThumb alt={imageThumb.title} fluid={imageThumb.localFile.childImageSharp.fluid} />
          </StyledBackground>
          )}
          <StyledCardWrapper>
            {button && (
            <StyledButtonWrapper>
              <StyledButton confetti={!image && !imageThumb} size="is-sm" type="button">
                {button.title}
              </StyledButton>
              {price && (
              <p>{price}</p>
              )}
            </StyledButtonWrapper>
            )}
            {info && (
            <StyledInfo>{info}</StyledInfo>
            )}
          </StyledCardWrapper>
        </StyledImageCard>
      );
    })}
  </StyledGridContainer>
);

export const Banner = ({
  column1Obj, layout, column2Obj, column3Obj, title,
} : {column1Obj: any, column2Obj: any, column3Obj: any, layout: string, title: string }) => (
  <StyledBanner className="component">
    <StyledTypography size="is-sm" variants="h3">{title}</StyledTypography>
    <Grid>
      {layout === 'one_columns' && <FullWidth subtitle={column1Obj.subtitle} title={column1Obj.title} button={column1Obj.button} secondButton={column1Obj.secondButton} image={column1Obj.image} />}
      {layout === 'two_columns' && <GridContainer items={[column1Obj, column2Obj]} desktopHeight={694} mobileHeight={380} />}
      {layout === 'three_columns' && <GridContainer items={[column1Obj, column2Obj, column3Obj]} desktopHeight={694} mobileHeight={380} />}
    </Grid>
  </StyledBanner>
);

const StyledBanner = styled.section`
  ${component};
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${mobileVW(30)};
  
  ${minTablet} {
    margin-bottom: ${desktopVW(60)};
  }
`;

const StyledContainer = styled.div`
  grid-column: 1 / 13;
  margin: 0 ${desktopVW(60)};
  height: ${mobileVW(378)};
  position: relative;
  border-radius: ${mobileVW(20)};
  overflow: hidden;
  color: ${color.white};
  display: flex; flex-direction: column; justify-content: space-between; align-items: center;
  
  ${minTablet} {
    border-radius: ${desktopVW(20)};
    height: ${desktopVW(673)};
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const StyledImageThumb = styled(Image)`
  width: ${mobileVW(105)};
  position: absolute;
  top: ${mobileVW(95)};
  left: 50%;
  transform: translateX(-50%);
  
  ${minTablet} {
    width: ${desktopVW(300)};
    top: ${desktopVW(140)};
  }
`;

const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  background: #F5F5F7;
  z-index: -1;
`;

const StyledWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0;
  
  ${minTablet} {
  }
`;

const StyledConfetti = styled(Confetti)`
  width: 100%;
  height: 100%;
  background: ${color.black};
  border-radius: ${mobileVW(40)};
    
  ${minTablet} {
    border-radius: ${desktopVW(20)};
  }
`;

const FullWidthTitle = styled(Typography)`
  color: ${color.white};
  margin-bottom: ${mobileVW(30)};
  
  ${minTablet} {
    margin-bottom: ${desktopVW(30)};
  }
`;

const StyledButton = styled(Button)`
  background: white;
  color: ${color.black};
  border: none;
  padding: ${mobileVW(15)} ${mobileVW(35)};
  font-size: ${mobileVW(14)};
  letter-spacing: 1px;
  margin-bottom: ${mobileVW(8)};

  ${({ confetti } : {confetti: boolean}) => confetti && (
    `
    border: 1px solid ${color.black};
    
    &:hover {
      background: ${color.black};
      border: 1px solid #fff;
    } 
    `
  )}

  ${minTablet} {
    margin-bottom: 0;
    padding: ${desktopVW(19)} ${desktopVW(40)};
    font-size: ${desktopVW(20)};
    margin-right: ${desktopVW(60)};
  }
`;

const StyledGridContainer = styled.div`
  grid-column: 2 / 12;
  position: relative;
  margin: 0 ${mobileVW(-15)};

  ${minTablet} {
    display: flex;
    grid-column: 1 / 13;
    margin: 0 ${desktopVW(60)};
  }
`;

/* @ts-ignore */
const StyledImageCard = styled(Link).attrs(({ type } : {type: any}) => ({ as: type }))`
  width: 100%;
  position: relative;
  border-radius: ${mobileVW(40)};
  overflow: hidden;
  height: ${({ mobilePx }: {mobilePx: number}) => mobileVW(mobilePx)};

  display: block;
  text-decoration: none;

  color: ${({ cardColor } : { cardColor: string }) => cardColor};
  transition: all .2s cubic-bezier(0.46, 0, 0.31, 1);
  cursor: pointer;
  
  ${minTablet} {
  .banner__hover {
    transform: scale(1.08);
    transition: transform .5s cubic-bezier(0.46, 0, 0.31, 1), opacity .5s cubic-bezier(0.46, 0, 0.31, 1);
  }

  &:hover {
    .banner__hover {
      transform: scale(1);
    }

  }
  ${({ hover } : {hover: boolean}) => hover && (
    `
      .banner__hover {
        transform: scale(1);
        transition: transform .5s cubic-bezier(0.46, 0, 0.31, 1), opacity .5s cubic-bezier(0.46, 0, 0.31, 1);
      }

      .image__animation {
        transition: all .5s cubic-bezier(0.46, 0, 0.31, 1);
        transform: scale(1.08);
      }

      &:hover {
        .banner__hover {
          opacity: 0;
          transform: scale(1.08);
        }

        .image__animation {
          transform: scale(1);
        }
      }
    `
  )}}

  ${minTablet} {
    height: ${({ desktopPx }: {desktopPx: number}) => desktopVW(desktopPx)};
    border-radius: ${desktopVW(30)};
  }

  & + & {
    margin-top: ${mobileVW(30)};

    ${minTablet} {
      margin-left: ${desktopVW(30)};
      margin-top: 0;
    }
  }
`;

const StyledCardTitle = styled(Typography)`

  ${maxTablet} {
    width: ${mobileVW(297)};
    margin: 0 auto;
  }
`;

const StyledCardWrapper = styled.div`
  position: absolute;
  bottom: ${mobileVW(25)};
  display: flex; flex-direction: column;
  justify-content: center; align-items: center;
  width: 100%;

  ${minTablet} {
    bottom: ${desktopVW(40)};
  }
`;

const StyledOverlay = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .overlay {
    background: #0000001f;
    top: 0; right: 0; bottom: 0; left: 0;
    position: absolute;
    z-index: 1;
    border-radius: ${mobileVW(40)};
    
    ${minTablet} {
      border-radius: ${desktopVW(20)};
    }
  }
`;

const StyledSubtitle = styled.p`
  margin-top: ${mobileVW(25)};
  margin-bottom: ${mobileVW(8)};
  
  ${minTablet} {
    margin-bottom: 0;
    margin-top: ${desktopVW(60)};
  }
`;

const StyledButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  /* margin-bottom: ${mobileVW(30)}; */
  border-bottom: 1px solid ${color.greyDark};
  
  padding-bottom: ${mobileVW(8)};
  margin-bottom: ${mobileVW(8)};
  
  p {
    ${maxTablet} {
      font-size: ${mobileVW(14)};
    }
  }

  ${minTablet} {
    display: flex;
    padding-bottom: ${desktopVW(30)};
    margin-bottom: ${desktopVW(15)};
  }
`;

const StyledInfo = styled.p`
  ${linkSm};
  /* color: #5B5E69; */

  ${maxTablet} {
    font-size: ${mobileVW(11)};
  }
  /* padding: ${desktopVW(15)}; */
  /* margin-top: ${desktopVW(60)}; */
`;

const StyledSub = styled.p`
  margin-bottom: ${mobileVW(30)}; 
  
  ${minTablet} {
    margin-bottom: ${desktopVW(30)}; 
  }
`;
