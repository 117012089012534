import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Button } from '../../shared/Button';
import { Typography } from '../../shared/Typography';

import {
  color, desktopVW, mobileVW, minTablet, component,
} from '../../styles';

interface componentProps {
  position: string
  background: string
  heading: string
  body: string
  button: {
    title: string
    target: string
    url: string
  }
  image: {
    altText: string
    title: string
    localFile : {
      childImageSharp : {
        fluid : {
          src: string
        }
      }
    }
  }
}

export const TextImage = ({
  position, background, heading, body, button, image,
}: componentProps) => (
  <Container className="component">
    {/* @ts-ignore */}
    <Inner backgroundColor={background} imagePosition={position}>
      <TextWrap>
        <Heading size="is-md" variants="h2">{heading}</Heading>
        {body && (
          <P>{body}</P>
        )}
        {button && (
          <StyledButton size="is-sm" type="link" url={button.url}>{ button.title }</StyledButton>
        )}
      </TextWrap>

      <StyledImage
        imagePosition={position}
        //  @ts-ignore
        fluid={image.localFile.childImageSharp.fluid}
        alt={image.altText}
      />
    </Inner>
  </Container>

);
const Heading = styled(Typography)`
  color: ${color.white};
`;

const Container = styled.section`
  ${component}
  padding: 0 ${mobileVW(15)};

  ${minTablet} {
    padding: 0 ${desktopVW(60)};
  }
`;

const StyledButton = styled(Button)`
  background-color: ${color.white};
  color: ${color.black};
  margin-top: ${mobileVW(30)};

  ${minTablet} {
    margin-top: ${desktopVW(30)};
  }
`;

const P = styled.p`
  color: ${color.white};
  margin-top: ${mobileVW(30)};

  ${minTablet} {
    margin-top: ${desktopVW(30)};
  }
`;

//  @ts-ignore
const Inner = styled.div`
  display: grid;
  grid-gap: 0;
  align-items: start;
  position: relative;
  grid-template-columns: 1fr;
  background-color: ${({ backgroundColor } : {backgroundColor: string}) => (backgroundColor === 'green' ? color.green : color.blue)};

  ${minTablet} {
    grid-template-columns: ${({ imagePosition } : {imagePosition: string}) => (imagePosition === 'right' ? '9fr 13fr' : '13fr 9fr')};
  }
`;

const TextWrap = styled.div`
  padding: ${mobileVW(60)} ${mobileVW(30)} ${mobileVW(30)} ${mobileVW(30)};
  ${minTablet} {
    padding: ${desktopVW(60)};
  }
`;

//  @ts-ignore
const StyledImage = styled(Image)`
  height: ${mobileVW(260)};
  order: ${({ imagePosition } : {imagePosition: string}) => (imagePosition === 'right' ? '1' : '-1')};
  background: ${color.black};

  ${minTablet} {
    height: 100%;
  }
`;
