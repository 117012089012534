import React from 'react';
import styled from 'styled-components';

export const Grid = ({ children, className } : { children: any, className?: any}) => (
  <StyledGrid className={className}>
    {children}
  </StyledGrid>
);

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0;
  align-items: start;

  position: relative; 
`;
