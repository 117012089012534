import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  component, desktopVW, minTablet, mobileVW, color, linkSm,
} from '../styles';
import { Typography } from '../shared/Typography';
import { isDevice } from '../utils';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

export const Reviews = ({ title } : { title: string }) => {
  const [reviews, setReviews] = useState([]);
  const [globalRating, setGlobalRating] = useState({ amount: 0, rating_average: 0 });
  const [indicator, setIndicator] = useState(1);

  const fetchReview = async () => {
    const data = await fetch('/.netlify/functions/reviews');
    const res = await data.json();

    setReviews(res.personRatings.ratings);
    setGlobalRating(res.globalRating.data);
  };

  useEffect(() => {
    fetchReview();

    return () => {
      setReviews([]);
    };
  }, []);

  const emoticon = (country : string) => {
    switch (country) {
      case 'NL':
        return '🇳🇱';
      case 'BE':
        return '🇧🇪';
      default:
        return '🦁';
    }
  };

  const roundNumDown = (num : number) => Math.round(num / 54) * 50;

  const review = (swiperActive : boolean) => reviews.map(({ comment, country, name }) => {
    const content = () => (
      <StyledReviewCard>
        <p>{comment}</p>
        <StyledName>
          <span>{emoticon(country)}</span>
          {' '}
          {name}
        </StyledName>
      </StyledReviewCard>
    );

    if (swiperActive) return <SwiperSlide style={{ height: 'auto' }}>{content()}</SwiperSlide>;
    return content();
  });

  return (
    <StyledReviews className="component">
      {title && (
      <StyledTitle variants="h4" size="is-sm">
        {title}
      </StyledTitle>
      )}

      <StyledRatingContainer>
        <StyledStarsContainer>
          {[...Array(5)].map((_empty : any, i) => (i < globalRating.rating_average / 2 ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.692 22.57"><path d="M23.662 8.564a.6.6 0 00-.476-.4l-7.466-1.09L12.378.309a.617.617 0 00-1.07 0L7.974 7.074.508 8.16a.595.595 0 00-.329 1.013l5.4 5.266-1.275 7.436a.595.595 0 00.863.625l6.678-3.51 6.678 3.51a.6.6 0 00.864-.625l-1.276-7.436 5.4-5.266a.6.6 0 00.151-.609z" fill="#ffcc48" /></svg>
          ) : null))}
        </StyledStarsContainer>
        <p>
          {globalRating.rating_average.toFixed(1)}
          {' '}
          van de 10
        </p>
      </StyledRatingContainer>
      {/* Eig illegaal maar ja */}
      <br />
      <StyledInfo href="https://www.webwinkelkeur.nl/webshop/mijnsilentdisco-nl_1209884/reviews" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.774 65.804"><path d="M54.346 8.003c-6.13 9.05-12.69 23-16.36 35.22-.45 1.49-2.27 2.39-3.72 2.54-1 .1-2.62-.12-3.14-1.11-.67-1.25-1.29-2.52-1.91-3.8-.27-.56-.54-1.12-.82-1.67v-.09l-.21-.39-.39-.68a16.2 16.2 0 00-2.16-2.9l-.14-.14-.24-.21c-.21-.16-.41-.33-.63-.48a13.3 13.3 0 00-1.4-.84c-1.72-.89-1-3.17.13-4.14a4.66 4.66 0 015.3-.59 14.14 14.14 0 015.27 4.92 133.525 133.525 0 018.17-17.69 19.27 19.27 0 108.28 8.82c1.2-2.45 2.44-4.81 3.7-7a26 26 0 012.2 26.62 9.42 9.42 0 00-.75 4.79 48.5 48.5 0 01.4 5 1.47 1.47 0 01-1.71 1.71 39.5 39.5 0 01-4.81-.53 8.54 8.54 0 00-4.29.55 26.08 26.08 0 11.47-45.78c1.23-1.93 2.54-3.81 3.91-5.64a32.9 32.9 0 10-4.72 59.09 18.27 18.27 0 017.49-.73 56.248 56.248 0 008.44.73 2.72 2.72 0 002.93-3 58.6 58.6 0 00-.76-8.59 16.809 16.809 0 01.71-7.3l.24-.63.15-.38c.14-.45.3-.83.38-1.19a32.92 32.92 0 00-10.01-34.49z" fill="#e9098a" /></svg>
        Meer dan
        {' '}
        {roundNumDown(globalRating.amount)}
        {' '}
        reviews
      </StyledInfo>
      {isDevice() ? (
        <>
          <StyledSwiper
            spaceBetween={15}
            slidesPerView={1}
            onSlideChange={(swiper) => setIndicator(swiper.activeIndex + 1)}
          >
            {review(true)}
          </StyledSwiper>
          <StyledLine>
            <StyledIndicator indicator={indicator} />
          </StyledLine>
        </>
      ) : (
        <StyledAnimation>
          <StyledReviewsContainer length={reviews.length}>
            {review(false)}
          </StyledReviewsContainer>
          <StyledReviewsContainer length={reviews.length}>
            {review(false)}
          </StyledReviewsContainer>
        </StyledAnimation>
      )}

    </StyledReviews>
  );
};

const StyledReviews = styled.section`
  ${component};
  text-align: center;
  overflow: hidden;
  padding-bottom: ${mobileVW(20)};
  
  ${minTablet} {
    padding-bottom: ${desktopVW(20)};
  }
  `;

const StyledTitle = styled(Typography)`
  margin-bottom: ${mobileVW(30)};

  ${minTablet} {
    margin-bottom: ${desktopVW(30)};
  }
  `;

const StyledRatingContainer = styled.div`
  margin-bottom: ${mobileVW(15)};
  background: #F5F5F7;
  display: inline-flex;
  padding: ${mobileVW(17)} ${mobileVW(15)};
  border-radius: ${mobileVW(10)};
  align-items: center;
  
  ${minTablet} {
    padding: ${desktopVW(19)} ${desktopVW(15)};
    border-radius: ${desktopVW(10)};
    margin-bottom: ${desktopVW(20)};
  }
`;

const StyledStarsContainer = styled.div`
  margin-right: ${mobileVW(15)};
  
  ${minTablet} {
    margin-right: ${desktopVW(20)};
  }
  
  svg {
    margin-right: ${mobileVW(5)};
    width: ${mobileVW(19)};
    
    ${minTablet} {
      margin-right: ${desktopVW(10)};
      width: ${desktopVW(24)};
    }
  }
  `;

const StyledInfo = styled.a`
  margin-bottom: ${mobileVW(30)};
  color: ${color.black};
  ${linkSm};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  ${minTablet} {
    margin-bottom: ${desktopVW(50)};
  }
  
  svg {
    width: ${mobileVW(20)};
    margin-right: ${mobileVW(15)};
    
    ${minTablet} {
      width: ${desktopVW(20)};
      margin-right: ${desktopVW(15)};
    }
  }
`;

const transform = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const StyledAnimation = styled.div`
  animation: ${transform} 80s linear infinite;
  display: inline-block;
  position: relative;
  will-change: transform;
`;

const StyledReviewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ length } : {length: number}) => length}, auto);
  box-sizing: border-box;
  
  &:last-child {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(100%)
  }
`;

const StyledReviewCard = styled.div`
  background: #fff;
  box-shadow: 0 0 ${mobileVW(34)} #00000008;
  border-radius: ${mobileVW(10)};
  width: 100%;
  text-align: left;
  padding: ${mobileVW(30)} ${mobileVW(30)} ${mobileVW(40)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  ${minTablet} {
    padding: ${desktopVW(30)} ${desktopVW(30)} ${desktopVW(40)};
    border-radius: ${desktopVW(10)};
    margin: 0 ${desktopVW(30)};
    box-shadow: 0 0 ${desktopVW(34)} #00000008;
    height: inherit;
    width: ${desktopVW(480)};
  }
`;

const StyledName = styled.p`
  ${linkSm}

  span {
    display: inline-block;
    margin-right: ${desktopVW(10)};
    align-self: flex-end;
  }
`;

const StyledSwiper = styled(Swiper)`
  padding: ${mobileVW(30)} ${mobileVW(15)};  
`;

const StyledLine = styled.div`
  width: auto;
  height: ${mobileVW(2)};
  background: #000;
  position: relative;
  margin: 0 ${mobileVW(30)};
  overflow: hidden;
`;

const StyledIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #CCCCCC;
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(${({ indicator } : {indicator : number}) => (100 / 10) * indicator}%);
`;
